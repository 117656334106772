// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/congrats_icon.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/roundup_logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".congrats-view{padding:60px 60px 40px 60px;text-align:left}.congrats-view .congrats-header p{margin:0}.congrats-view .congrats-content{text-align:center}.congrats-view .congrats-content h3{font-family:ff-tisa-web-pro;font-size:24px;font-weight:400}.congrats-view .congrats-content .congrats-icon{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;width:100px;height:100px;display:inline-block}.congrats-view .congrats-content p{font-size:10px}.congrats-view .roundup-logo{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:137px 14px;background-repeat:no-repeat;background-position:50%;width:100%;height:14px;margin:0 0 20px}.congrats-view .accept{background:#000;padding:10px;min-width:90%;color:#fff;margin:auto}", ""]);
// Exports
module.exports = exports;
